<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Fehler bei der Abfrage der Daten</h4>
      <div class="alert-body">
        Es wurde kein Mitarbeiter mit dieser ID gefunden. Gehen Sie bitte zurück
        zur
        <b-link class="alert-link" :to="{ name: 'mitarbeiter' }">
          Mitarbeiterliste
        </b-link>
        und wählen Sie einen anderen Mitarbeiter aus.
      </div>
    </b-alert>

    <b-row v-if="userData">
      <b-col>
        <mitarbeiter-bearbeiten-card :user-data="userData" />
      </b-col>
    </b-row>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink, BRow, BCol } from 'bootstrap-vue';
import MitarbeiterBearbeitenCard from '@/layouts/components/widgets/MitarbeiterBearbeitenCard.vue';

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,

    MitarbeiterBearbeitenCard,
  },
  data() {
    return {
      userData: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    // Initialize Page Title
    to.meta.pageTitle = `Mitarbeiter bearbeiten`;
    next();
  },
  async created() {
    const qs = require('qs');
    const query = qs.stringify(
      {
        populate: [
          'Verfuegbarkeit',
          'Anschrift',
          'Kontakt',
          'Kenntnisse',
          'User.avatar',
        ],
      },
      {
        encodeValuesOnly: true,
        indices: false,
      }
    );
    await this.$http
      .get(`/mitarbeiters/${this.$route.params.id}?${query}`)
      .then(response => {
        this.userData = response.data.data;
      })
      .catch(err => console.log(err));

    // Change Page Title
    this.$route.meta.pageTitle = `Mitarbeiter ${this.userData.Vorname} ${this.userData.Nachname} bearbeiten`;
    this.$router.replace({ query: { temp: Date.now() } });
    this.$router.replace({ query: { temp: undefined } });
  },
};
</script>

<style></style>
