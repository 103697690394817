<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="imageSrc"
          :text="`${userData.Vorname.charAt()}${userData.Nachname.charAt()}`"
          :variant="`light-primary`"
          size="90px"
        />
      </template>
      <h4 class="mb-1">{{ userData.Vorname }} {{ userData.Nachname }}</h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input
            ref="refInputEl"
            type="file"
            accept="image/*"
            class="d-none"
            @input="setNewImage"
          />
          <span class="d-none d-sm-inline">Bild Aktualisieren</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Bild Entfernen</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <validation-observer ref="updateEmployeeForm" #default="{ invalid }">
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <b-col cols="12">
            <h4>Allgemein</h4>
          </b-col>

          <!-- Grunddaten - Status -->
          <b-col cols="12">
            <b-row>
              <b-col cols="12" md="4">
                <b-form-group label="Status" label-for="status">
                  <v-select
                    v-model="userData.Verfuegbar"
                    id="status"
                    :reduce="status => status.value"
                    :clearable="false"
                    :options="[
                      {
                        label: 'Verfügbar',
                        value: 'Verfuegbar',
                        color: 'success',
                      },
                      {
                        label: 'Im Auftrag',
                        value: 'Im_Auftrag',
                        color: 'primary',
                      },
                      { label: 'Krank', value: 'Krank', color: 'danger' },
                    ]"
                  >
                    <template #selected-option="{ label, color }">
                      <b-badge :variant="color">
                        {{ label }}
                      </b-badge>
                    </template>
                    <template #option="{ label, color }">
                      <b-badge :variant="color">
                        {{ label }}
                      </b-badge>
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>

          <!-- Field: Username -->
          <b-col cols="12" md="4">
            <b-form-group label="Username" label-for="username">
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required|min:5"
              >
                <b-form-input
                  id="username"
                  placeholder="maxmustermann"
                  v-model="userData.User.username"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="userIdNotUnique" class="text-danger"
                  >Benutzername bereits vergeben</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="4">
            <b-form-group label="Email" label-for="email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.User.email"
                  type="email"
                  placeholder="mitarbeiter@temporatio.de"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small v-if="emailNotUnique" class="text-danger"
                  >Email-Adresse bereits vergeben</small
                >
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <!-- Passwort -->
            <b-form-group label-for="password" label="Passwort">
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="min:6"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="password"
                    v-model="userData.User.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Vorname -->
          <b-col cols="12" md="4">
            <b-form-group label="Vorname" label-for="vorname">
              <validation-provider
                #default="{ errors }"
                name="Vorname"
                vid="vorname"
                rules="required"
                placeholder="Max"
              >
                <b-form-input id="vorname" v-model="userData.Vorname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Nachname -->
          <b-col cols="12" md="4">
            <b-form-group label="Nachname" label-for="nachname">
              <validation-provider
                #default="{ errors }"
                name="Nachname"
                vid="nachname"
                rules="required"
                placeholder="Mustermann"
              >
                <b-form-input id="nachname" v-model="userData.Nachname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Telefon -->
          <b-col cols="12" md="4">
            <b-form-group label="Telefon" label-for="telefon">
              <b-form-input
                id="telefon"
                v-model="userData.Kontakt.Telefon"
                placeholder="1234 12345678"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Mobil -->
          <b-col cols="12" md="4">
            <b-form-group label="Mobil" label-for="mobil">
              <b-form-input
                id="mobil"
                v-model="userData.Kontakt.Mobil"
                placeholder="1234 12345678"
              />
            </b-form-group>
          </b-col>

          <!-- Geburtstag -->
          <b-col cols="12" md="4">
            <b-form-group label="Geburtstag" label-for="Geburtstag">
              <b-form-datepicker
                id="geburtstag"
                v-model="userData.Geburtstag"
                v-bind="{
                  labelPrevDecade: 'Vorheriges Jahrzehnt',
                  labelPrevYear: 'Vorheriges Jahr',
                  labelPrevMonth: 'Vorheriger Monat',
                  labelCurrentMonth: 'Aktueller Monat',
                  labelNextMonth: 'Nächster Monat',
                  labelNextYear: 'Nächstes Jahr',
                  labelNextDecade: 'Nächstes Jahrzehnt',
                  labelToday: 'Heute',
                  labelSelected: 'Ausgewähltes Datum',
                  labelNoDateSelected: 'Kein Datum gewählt',
                  labelCalendar: 'Kalender',
                  labelNav: 'Kalendernavigation',
                  labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                }"
                :start-weekday="1"
                show-decade-nav
                locale="de"
                aria-controls="geburtstag"
                placeholder="Datum auswählen"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Anschrift</h4>
          </b-col>

          <!-- Field: Straße -->
          <b-col cols="12" md="4">
            <b-form-group label="Straße" label-for="strasse">
              <b-form-input
                id="strasse"
                v-model="userData.Anschrift.Strasse"
                placeholder="Musterstraße"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Hausnummer -->
          <b-col cols="12" md="4">
            <b-form-group label="Hausnummer" label-for="hausnummer">
              <b-form-input
                id="hausnummer"
                v-model="userData.Anschrift.Hausnummer"
                placeholder="10"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Adresszusatz -->
          <b-col cols="12" md="4">
            <b-form-group label="Adresszusatz" label-for="adresszusatz">
              <b-form-input
                id="adresszusatz"
                v-model="userData.Anschrift.Zusatz"
                placeholder="10"
              />
            </b-form-group>
          </b-col>

          <!-- Mobilität -->
          <b-col cols="12" md="4">
            <b-form-group label="Mobilität" label-for="mobilitaet">
              <v-select
                v-model="userData.Mobilitaet"
                id="mobilitaet"
                :reduce="mobilitaet => mobilitaet.value"
                placeholder="Mobilität auswählen"
                :options="[
                  { label: 'PKW-Führerschein', value: 'PKW-Fuehrerschein' },
                  { label: 'LKW-Führerschein', value: 'LKW-Fuehrerschein' },
                  { label: 'Öffentliche', value: 'Oeffentliche' },
                ]"
              />
            </b-form-group>
          </b-col>

          <!-- Field: PLZ -->
          <b-col cols="12" md="4">
            <b-form-group label="PLZ" label-for="plz">
              <b-form-input
                id="plz"
                type="number"
                placeholder="12345"
                v-model="userData.Anschrift.PLZ"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Stadt -->
          <b-col cols="12" md="4">
            <b-form-group label="Stadt" label-for="stadt">
              <b-form-input
                id="stadt"
                v-model="userData.Anschrift.Stadt"
                placeholder="Musterstadt"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Anstellung</h4>
          </b-col>

          <!-- Anstellungsart -->
          <b-col cols="12" md="4">
            <b-form-group label="Anstellungsart" label-for="anstellungsart">
              <v-select
                v-model="userData.Anstellungsart"
                id="anstellungsart"
                :reduce="anstellungsart => anstellungsart.value"
                placeholder="Anstellungsart auswählen"
                :options="[
                  { label: 'Fest', value: 'Fest' },
                  { label: 'Teilzeit', value: 'Teilzeit' },
                  { label: 'Mini', value: 'Mini' },
                  { label: 'Frei', value: 'Frei' },
                ]"
              />
            </b-form-group>
          </b-col>

          <!-- Stundensatz -->
          <b-col cols="12" md="4">
            <b-form-group label="Stundensatz" label-for="stundensatz">
              <b-input-group append="€ pro Stunde">
                <b-form-input
                  id="stundensatz"
                  v-model="userData.Stundensatz"
                  name="stundensatz"
                  type="number"
                  placeholder="30"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <!-- Kenntnisse -->
            <b-form-group label="Kenntnisse">
              <div class="d-flex flex-row">
                <b-form-checkbox
                  id="qualitaetssicherung"
                  v-model="userData.Kenntnisse.Qualitaetssicherung"
                  name="qualitaetssicherung"
                  >Qualitätssicherung</b-form-checkbox
                >
                <b-form-checkbox
                  class="ml-2"
                  id="messmittel"
                  v-model="userData.Kenntnisse.Messmittel"
                  name="messmittel"
                  >Messmittel</b-form-checkbox
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <!-- Arbeitserlaubnis -->
            <b-form-group label="Arbeitserlaubnis" label-for="arbeitserlaubnis">
              <div class="d-flex flex-row">
                <b-form-checkbox
                  id="arbeitserlaubnis"
                  v-model="userData.Arbeitserlaubnis"
                  name="arbeitserlaubnis"
                  >Hat eine Arbeitserlaubnis</b-form-checkbox
                >
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" v-if="userData.Arbeitserlaubnis">
            <!-- Arbeitserlaubnis bis -->
            <b-form-group
              label="Arbeitserlaubnis bis"
              label-for="arbeitserlaubnis-bis"
            >
              <b-form-datepicker
                id="arbeitserlaubnis-bis"
                v-model="userData.Gueltigkeit_Arbeitserlaubnis"
                v-bind="{
                  labelPrevDecade: 'Vorheriges Jahrzehnt',
                  labelPrevYear: 'Vorheriges Jahr',
                  labelPrevMonth: 'Vorheriger Monat',
                  labelCurrentMonth: 'Aktueller Monat',
                  labelNextMonth: 'Nächster Monat',
                  labelNextYear: 'Nächstes Jahr',
                  labelNextDecade: 'Nächstes Jahrzehnt',
                  labelToday: 'Heute',
                  labelSelected: 'Ausgewähltes Datum',
                  labelNoDateSelected: 'Kein Datum gewählt',
                  labelCalendar: 'Kalender',
                  labelNav: 'Kalendernavigation',
                  labelHelp: 'Monat/Jahr mit Pfeiltasten ändern',
                }"
                :start-weekday="1"
                show-decade-nav
                locale="de"
                aria-controls="arbeitserlaubnis-bis"
                placeholder="Datum auswählen"
              />
            </b-form-group>
          </b-col>

          <!-- Bewertung -->
          <b-col cols="12" md="4">
            <b-form-group label="Bewertung" label-for="bewertung">
              <b-form-radio-group
                id="bewertung"
                class="d-flex justify-content-between mt-1"
                v-model="userData.Bewertung"
                :options="[
                  { text: '0', value: 0 },
                  { text: '1', value: 1 },
                  { text: '2', value: 2 },
                  { text: '3', value: 3 },
                  { text: '4', value: 4 },
                  { text: '5', value: 5 },
                  { text: '6', value: 6 },
                ]"
                name="radio-validation"
              >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Verfügbarkeit</h4>
          </b-col>

          <b-col cols="12">
            <!-- Verfügbarkeit -->
            <div class="checkbox-group">
              <b-form-checkbox
                id="fruehschicht"
                v-model="userData.Verfuegbarkeit.Fruehschicht"
                name="fruehschicht"
                >Frühschicht</b-form-checkbox
              >
              <b-form-checkbox
                id="spaetschicht"
                v-model="userData.Verfuegbarkeit.Spaetschicht"
                name="spaetschicht"
                >Spätschicht</b-form-checkbox
              >
              <b-form-checkbox
                id="nachtschicht"
                v-model="userData.Verfuegbarkeit.Nachtschicht"
                name="nachtschicht"
                >Nachtschicht</b-form-checkbox
              >
              <b-form-checkbox
                id="montag"
                v-model="userData.Verfuegbarkeit.Montag"
                name="montag"
                >Montag</b-form-checkbox
              >
              <b-form-checkbox
                id="dienstag"
                v-model="userData.Verfuegbarkeit.Dienstag"
                name="dienstag"
                >Dienstag</b-form-checkbox
              >
              <b-form-checkbox
                id="mittwoch"
                v-model="userData.Verfuegbarkeit.Mittwoch"
                name="mittwoch"
                >Mittwoch</b-form-checkbox
              >
              <b-form-checkbox
                id="donnerstag"
                v-model="userData.Verfuegbarkeit.Donnerstag"
                name="donnerstag"
                >Donnerstag</b-form-checkbox
              >
              <b-form-checkbox
                id="freitag"
                v-model="userData.Verfuegbarkeit.Freitag"
                name="freitag"
                >Freitag</b-form-checkbox
              >
              <b-form-checkbox
                id="samstag"
                v-model="userData.Verfuegbarkeit.Samstag"
                name="samstag"
                >Samstag</b-form-checkbox
              >
              <b-form-checkbox
                id="sonntag"
                v-model="userData.Verfuegbarkeit.Sonntag"
                name="sonntag"
                >Sonntag</b-form-checkbox
              >
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="mt-1">
            <h4>Sonstiges</h4>
          </b-col>

          <!-- Sonstiges -->
          <b-col cols="12">
            <b-form-group label="Sonstige Informationen" label-for="sonstiges">
              <b-form-textarea
                id="sonstiges"
                v-model="userData.Sonstiges"
                rows="3"
                name="sonstiges"
                placeholder="Sonstige Informationen"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateEmployee"
    >
      Änderungen Speichern
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="abort"
    >
      Abbrechen
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
  BFormRadioGroup,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import de from 'vee-validate/dist/locale/de.json';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

localize('de', de);

const base64Encode = data =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export default {
  components: {
    BButton,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormTextarea,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  mixins: [togglePasswordVisibility],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: null,
      imageSrc: '',
      employeeIdNotUnique: false,
      userIdNotUnique: false,
      emailNotUnique: false,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  watch: {
    'userData.Mitarbeiternummer': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              Mitarbeiternummer: {
                $eq: this.userData.Mitarbeiternummer,
              },
            },
            fields: ['id', 'Mitarbeiternummer'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/mitarbeiters?${query}`)
          .then(response => {
            if (response.data.data.length > 0) {
              this.employeeIdNotUnique = true;
            } else {
              this.employeeIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.User.username': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              username: {
                $eq: this.userData.User.username,
              },
            },
            fields: ['id', 'username'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.userIdNotUnique = true;
            } else {
              this.userIdNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
    'userData.User.email': async function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const qs = require('qs');
        const query = qs.stringify(
          {
            filters: {
              email: {
                $eq: this.userData.User.email,
              },
            },
            fields: ['id', 'email'],
          },
          {
            encodeValuesOnly: true,
          }
        );

        await this.$http
          .get(`/users?${query}`)
          .then(response => {
            if (response.data.length > 0) {
              this.emailNotUnique = true;
            } else {
              this.emailNotUnique = false;
            }
          })
          .catch(err => console.log(err.response.data.error.message));
      }
    },
  },
  methods: {
    setNewImage() {
      this.image = this.$refs.refInputEl.files[0];
      base64Encode(this.image)
        .then(value => {
          this.imageSrc = value;
        })
        .catch(() => {
          this.imageSrc = '';
        });
    },
    async updateEmployee() {
      this.$refs.updateEmployeeForm.validate().then(async isValid => {
        if (!isValid) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Eingabe nicht korrekt!`,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: `Die Eingabe ist nicht korrekt. Bitte überprüfen Sie alle Felder und probieren Sie es noch einmal.`,
            },
          });
          return;
        }

        // Set Employee Data
        await this.$http
          .put(`/users/${this.userData.User.id}`, {
            username: this.userData.User.username,
            email: this.userData.User.email,
            password: this.userData.User.password,
          })
          .then(async () => {
            // Set Image
            if (this.image) {
              if (this.userData.User.avatar?.id) {
                await this.$http
                  .delete(`/upload/files/${this.userData.User.avatar.id}`)
                  .catch(err => console.log(err.response.data.error.message));
                this.userData.User.avatar = null;
              }

              const formData = new FormData();
              formData.append('field', 'avatar');
              formData.append('ref', 'plugin::users-permissions.user');
              formData.append('refId', this.userData.User.id);
              formData.append('files', this.image, this.image.name);

              await this.$http
                .post('/upload', formData)
                .catch(err => console.log(err.response.data.error.message));
            }

            await this.$http
              .put(`/mitarbeiters/${this.userData.id}`, { data: this.userData })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Mitarbeiter aktualisiert`,
                    icon: 'SaveIcon',
                    variant: 'success',
                    text: `Der Mitarbeiter ${this.userData.Vorname} ${this.userData.Nachname} wurde erfolgreich aktualisiert.`,
                  },
                });
                this.$router.push({
                  name: 'mitarbeiter-ansicht',
                  params: { id: this.$route.params.id },
                });
              })
              .catch(err => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Aktualisierung des Mitarbeiters fehlgeschlagen!`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: `Der Mitarbeiter konnte nicht aktualisiert werden. Bitte überprüfen Sie die Eingabe und probieren Sie es noch einmal.`,
                  },
                });
                console.log(err.response.data.error.message);
              });
          })
          .catch(err => console.log(err));
      });
    },
    abort() {
      this.$router.push({
        name: 'mitarbeiter-ansicht',
        params: { id: this.$route.params.id },
      });
    },
  },
  beforeMount() {
    if (this.userData.User.avatar) {
      this.imageSrc =
        this.$dbBaseUrl + this.userData.User.avatar.formats.thumbnail.url;
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.checkbox-group {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.checkbox-group .custom-checkbox {
  width: 10rem;
  margin-bottom: 0.5rem;
}
</style>
